import React, { useEffect,useState } from 'react';
import classes from './loading.module.css';
import { useNavigate } from 'react-router-dom';

const Loading = () => {
    const navigate = useNavigate();

    useEffect(()=>{
        const timeOut = setTimeout(() => {
                navigate("/home")
        }, 1500);

        return () => {
            clearTimeout(timeOut);
        }
    },[])

    return (
        <div className={classes.container}>
            <div className={classes.loadingContainer}>
                <div className={classes.loading}>
                </div>
            </div>
        </div>
    )
}

export default Loading
import React from 'react'
import classes from "./Home.module.css"
import icons from '../../Images/image';

const Home = () => {
  return (
    <div className={classes.profileBody}>
      <div className={classes.bannerContainer}>
        <img
          className={classes.banner}
          src={icons.banner}
        />
      </div>
      <div className={classes.qualification}>
          <h1 className={classes.qualificationTitle}>Qualifications</h1>
          <ul>
            <li className={classes.qualificationText}>
              Recently in Sophomore Year Of Department Computer Science Of Royal University Of Phnom Penh
            </li>
            <li className={classes.qualificationText}>
              Graduated since 2020: &nbsp; Chbar Ampov High School General Education
            </li>
            <li className={classes.qualificationText}>
              Graduated since 2019: &nbsp; Beltei International School English For Education
            </li>
          </ul>
      </div>

      <div className={classes.skills}>
        <h1 className={classes.skillTitle}>Skills</h1>
        <div className={classes.skillContainer}>
          <div className={classes.skillCol}>
            <ul>
                <li className={classes.skillText}>
                    <span style={{textDecoration: "underline"}}>Programming Languages :&nbsp;</span>
                    C &nbsp;<img src={icons.c} className={classes.skillIcon}/>&nbsp;,
                    C++ <img src={icons.cpp} className={classes.skillIcon}/>&nbsp;, 
                    JavaScript <img src={icons.js} className={classes.skillIcon}/>&nbsp;, 
                    Python <img src={icons.python} className={classes.skillIcon}/>
                </li>
                <li className={classes.skillText}>
                    <span style={{textDecoration: "underline"}}>Basic Web Development :&nbsp;</span>
                    HTML/CSS/JavaScript 
                    <img src={icons.html} className={classes.skillIcon}/>
                    <img src={icons.css} className={classes.skillIcon}/>
                    <img src={icons.js} className={classes.skillIcon}/>
                </li>
                <li className={classes.skillText}>
                    <span style={{textDecoration: "underline"}}>Frameworks :&nbsp;</span>
                    ReactJs <img src={icons.reactJS} className={classes.skillIcon}/> &nbsp;, 
                    Bootstrap <img src={icons.bootstrap} className={classes.skillIcon}/>,&nbsp; 
                    JQuery <img src={icons.jquery} className={classes.skillIcon}/>
                </li>
                <li className={classes.skillText}>
                    <span style={{textDecoration: "underline"}}>Server Side :&nbsp;</span>
                    NodeJs <img src={icons.node} className={classes.skillIcon}/>&nbsp;, 
                    ExpressJs <img src={icons.express} className={classes.skillIcon}/>,&nbsp;
                    Sql/MySql <img src={icons.mysql} className={classes.skillIcon}/>&nbsp;, 
                    KnexJs <img src={icons.knex} className={classes.skillIcon}/>&nbsp;, 
                    Redis <img src={icons.redis} className={classes.skillIcon}/>    
                </li>
              </ul>
          </div>
        </div>
      </div>

      <div className={classes.motivation}>
          <div className={classes.motivationText}>
              Remotely Work || Office Work Is My Passion
          </div>
      </div>
    </div>
  )
}

export default Home
import React from "react";
import classes from "./welcome.module.css";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowsAlt } from "react-icons/ai";

const Welcome = () => {
  const navigate = useNavigate();

  const handleSeeMoreClick = async () => {
    navigate("/loading");
  };

  return (
    <div className={classes.container}>
      <div className={classes.loading}></div>
      <div className={classes.textContainer}>
        <div className={classes.helloText}>Hello There !</div>

        <div className={classes.wrapper}>
          <div className={classes.animatedStaticText}>I'm</div>
          <ul className={classes.animatedLi}>
            <li>
              <span>Yim Sotharoth</span>
            </li>
            <li>
              <span>A Sophomore</span>
            </li>
            <li>
              <span>A Developer</span>
            </li>
          </ul>
        </div>
      </div>
      <div className={classes.btnContainer}>
        <div className={classes.btn} onClick={handleSeeMoreClick}>
          Portfolio &nbsp; <AiOutlineArrowsAlt />
        </div>
      </div>
      <div className={classes.copyRight}>
        Copy Right &copy; YIM SOTHAROTH 2022
      </div>
    </div>
  );
};

export default Welcome;

import TiktokS from './tiktok.png';
import backImage from './backImage.jpg';
import codingB from './coding.png';
import css from './css.png';
import html from './html.png';
import codingS from './icons8-coding-24.png';
import schoolB from './icons8-school-30.png';
import schoolS from './icons8-school-24.png';
import js from './js.png';
import mysql from './mysql.png';
import node from './node.png';
import python from './python.png';
import reactJS from './reactJS.png';
import redis from './redis.png';
import phoneS from './phoneS.png';
import fb from './fb.png';
import ig from './ig.png';
import github from './github.png';
import gmail from './email.png';
import bootstrap from './bootstrap.png';
import jquery from './jquery.png';
import jwt from './jwt.png';
import express from './express.png';
import knex from './knex.png';
import c from './c.png';
import cpp from './c++.png';
import htmlcssjs from './htmlcssjs.png';
import portfolio from './portfolio.PNG';
import eCommerce1 from './e-commerce1.png';
import eCommerce2 from './e-commerce2.png';
import eCommerce3 from './e-commerce3.png';
import htmlEcommerce1 from './htmlEcommerce1.png';
import htmlEcommerce2 from './htmlEcommerce2.png';
import liveChat from './livechat.png';
import teacherStudent from './teacher-student-management.png';
import devImage from './DevImage.png';
import mail from './mail.png';
import send from './send.png';
import logo from './logo.jpg';
import logoBig from './logoBig.png';
import cambodia from './cambodia.png';
import map from './map.png';
import logoCircle from './logoCircle.png';
import banner from './banner.png';

const icons = {
    eCommerce1,
    eCommerce2,
    eCommerce3,
    htmlEcommerce1,
    htmlEcommerce2,
    liveChat,
    teacherStudent,
    TiktokS,
    backImage,
    codingB,
    codingS,
    css,
    html,
    schoolB,
    schoolS,
    js,
    mysql,
    node,
    python,
    reactJS,
    redis,
    phoneS,
    fb,
    ig,
    github,
    gmail,
    bootstrap,
    jquery,
    jwt,
    express,
    knex,
    c,
    cpp,
    htmlcssjs,
    portfolio,
    devImage,
    mail,
    send,
    logo,
    logoBig,
    cambodia,
    map,
    logoCircle,
    banner

}

export default icons;
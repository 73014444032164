import React from 'react';
import { Routes,Route } from 'react-router-dom';
import Contact from './component/contact/Contact';
import Container from './component/container/Container';
import Home from './component/Home/Home';
import Class from './component/class/Class';
import SeeMore from './component/Detail/SeeMore';
import Welcome from './component/Welcome/welcome';
import Loading from './component/Loading/Loading';
import Form from './component/Form/Form';

export default function App() {

  return (
    <>
      <Container>
        <Routes>
            <Route path="/" element={<Welcome/>}/>
            <Route path="/loading" element={<Loading/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/class' element={<Class/>}/>
            <Route path="/detail" element={<SeeMore/>}/>
            <Route path="/sendMessage" element={<Form/>}/>
        </Routes>
      </Container>
    </>
  );
}
import React from 'react'
import classes from './footer.module.css'
import icons from '../../Images/image';

const Footer = () => {

  return (
    <div className={classes.profileFooter}>

      <div className={classes.logoContainer}>
        <img 
          className={classes.logo}
          src={icons.logo}
        />
        <div className={classes.logoTextContainer}>
          <div className={classes.logoText}>
            &#60;jBeanny&#47;&#62;
          </div>
        </div>
      </div>

      <div className={classes.detailInfo}>
        <div className={classes.bigName}>
            Sotharoth
        </div>
        <div className={classes.copyRight}>
          Copy Right  &copy; Yim Sotharoth 2022
        </div>
      </div>

      <div className={classes.addressContainer}>
        <div className={classes.addressRow}>
          Phnom Penh City, Cambodia 
          <img
            className={classes.country}
            src={icons.cambodia}
          />
        </div>
        <div className={classes.addressRow}>
          Chbar Ampov District
        </div>
        <div className={classes.addressRow}>
          Borey Pheng Huot Boeung Snor
        </div>
        <div className={classes.addressRow}>
          #Street P09, Home &#8470; 21
        </div>
      </div>

  </div>
  )
}

export default Footer
import React from 'react'
import classes from './office.module.css';

import {BsArrowLeftRight} from 'react-icons/bs';
import banner from '../../Images/banner.png';

const Office = () => {
  
  return (
    <div className={classes.container}>
      <div className={classes.content}>
          <div className={classes.title}>
            Microsoft Office
          </div>
          <ul className={classes.ul}>
            <li className={classes.li}>
              1. Cirriculum Vitae (CV) / Résumé / Cover Letter
            </li>
            <li className={classes.li}>
              2. Translate documents English 
              &nbsp; <BsArrowLeftRight className={classes.liIcon}/> 
              &nbsp; Khmer
            </li>
            <li className={classes.li}>
              3. PowerPoint animated slide shows (excluded student's work)
            </li>
          </ul>
      </div>
      <div className={classes.content}>
          <div className={classes.title}>
            Web Development
          </div>
          <ul className={classes.ul}>
            <li className={classes.li}>
              1. Web Portfolio / Web Personal Profile
            </li>
            <li className={classes.li}>
              2. News Website / Documents Website
            </li>
            <li className={classes.li}>
              3. E-Commerce / Business Website
            </li>
          </ul>
      </div>
      <div className={classes.serviceText}>
        All Services Serve With Responsibility
      </div>

      <div className={classes.bannerContainer}>
        <img src={banner} className={classes.banner}/>
      </div>
    </div>
  )
}

export default Office
import React from 'react'
import classes from './course.module.css';

import icons from '../../Images/image';
import banner from '../../Images/banner.png';

const Course = () => {
  
  return (
    <div className={classes.container}>
      <div className={classes.content}>
          <div className={classes.title}>
            Basic Programming
          </div>
          <ul className={classes.ul}>
            <li className={classes.li}>
              C Programming Language&nbsp;<img src={icons.c} className={classes.icon}/>
            </li>
            <li className={classes.li}>
              C++ Programming Language&nbsp;<img src={icons.cpp} className={classes.icon}/>
            </li>
            <li className={classes.li}>
              JavaScript Programming Language&nbsp;<img src={icons.js} className={classes.icon}/>
            </li>
          </ul>
      </div>
      <div className={classes.content}>
          <div className={classes.title}>
            Web Development
          </div>
          <ul className={classes.ul}>
            <li className={classes.li}>
              <span style={{textDecoration: "underline"}}>Basic Web Development :&nbsp;</span>
                HTML / CSS / JavaScript
              <img src={icons.html} className={classes.icon}/>
              <img src={icons.css} className={classes.icon}/>
              <img src={icons.js} className={classes.icon}/>
            </li>
            <li className={classes.li}>
              <span style={{textDecoration: "underline"}}>Frameworks :&nbsp;</span>
              Bootstrap <img src={icons.bootstrap} className={classes.icon}/>, 
              JQuery <img src={icons.jquery} className={classes.icon}/>, 
              React JS<img src={icons.reactJS} className={classes.icon}/>
            </li>
            <li className={classes.li}>
              <span style={{textDecoration: "underline"}}>Server Side :&nbsp;</span>
              Node JS <img src={icons.node} className={classes.icon}/>,&nbsp;
              Express JS <img src={icons.express} className={classes.icon}/>,&nbsp; 
              MySql/Sql <img src={icons.mysql} className={classes.icon}/>,&nbsp; 
              Knex JS <img src={icons.knex} className={classes.icon}/>,&nbsp; 
              JWT <img src={icons.jwt} className={classes.icon}/>
            </li>
          </ul>
      </div>
      <div className={classes.serviceText}>
        All Services Serve With Responsibility
      </div>

      <div className={classes.bannerContainer}>
        <img src={banner} className={classes.banner}/>
      </div>
    </div>
  )
}

export default Course
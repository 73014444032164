import icons from "../../Images/image";

const Carousel = [
    {
        image: icons.portfolio,
        project_name: "My Portfolio",
        languages: "ReactJs / SCSS ",
        description: "My current portfolio which you are reading in"
    },
    {
        image: icons.liveChat,
        project_name: "Live Chat Project",
        languages: "ReactJs / Ant Design / SCSS  / Node JS / Express JS / JWT / Knex Js",
        description: "Get Comments From Live Videos From Facebook"
    },
    {
        image: icons.eCommerce1,
        preview1: icons.eCommerce2,
        preview2: icons.eCommerce3,
        project_name: "E-Commerce Project",
        languages: "ReactJs / CSS / Node JS / Express JS",
        description: "Online Shopping With Shopping Cart"
    },
    {
      image: icons.teacherStudent,
      project_name: "Teachers/Students Management App Project",
      languages: "ReactJs / CSS / Ant Design / Restful API",
      description: "Manage Teachers/Courses/Students Data"
    },
    {
        image: icons.htmlEcommerce1,
        preview1: icons.htmlEcommerce2,
        project_name: "E-Commerce Project",
        languages: "HTML / CSS / JavaScript / JQuery",
        description: "Online Selling PCs / Phones / Accessories"
    },
]

export default Carousel;


import React from 'react'
import classes from './contact.module.css';

import { useNavigate } from 'react-router-dom';
import {FiExternalLink,FiCopy,FiPhoneCall} from 'react-icons/fi';
import {AiOutlineMail} from 'react-icons/ai';
import icons from '../../Images/image';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const navigate = useNavigate();
  
  const handleNavigate = (url) => {
    window.open(`https://www.${url}`);
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
    .then(()=>{
        toast.info("Copied To Clipboard !")
    })
    .catch((err)=>{
        toast(err)
    })
  }
  return (
    <div className={classes.container}>
      <div className={classes.title}>Socials</div>

      {/* tiktok */}
      <div className={classes.row}>
        <div className={classes.icon}>
            <img src={icons.TiktokS} className={classes.iconLogo}/>
        </div>
        <div className={classes.url}>
            tiktok.com/@codingmystery
        </div>
        <div className={classes.icon}>
            <FiExternalLink
              onClick={()=>{
                handleNavigate("tiktok.com/@codingmystery")
              }}
            />
        </div>
      </div>

      {/* phone */}
      <div className={classes.row}>
        <div className={classes.icon}>
            <img src={icons.phoneS} className={classes.iconLogo}/>
        </div>
        <div className={classes.url}>
            +855 77 797 782
        </div>
        <div className={classes.icon}>
          <FiCopy
              onClick={()=>{
                handleCopy("+855 77 797 782")
              }}
          />
          <a 
            href="tel:077797782"
          >
            <FiPhoneCall
              className={classes.makeCall}
            />
          </a>
        </div>
      </div>
      
      {/* fb */}
      <div className={classes.row}>
        <div className={classes.icon}>
          <img src={icons.fb} className={classes.iconLogo}/>
        </div>
        <div className={classes.url}>
          https://www.facebook.com/sotharoth.yim/
        </div>
        <div className={classes.icon}>
          <FiExternalLink
              onClick={()=>{
                handleNavigate("facebook.com/sotharoth.yim/")
              }}
          />
        </div>
      </div>

      {/* ig */}
      <div className={classes.row}>
        <div className={classes.icon}>
          <img src={icons.ig} className={classes.iconLogo}/>
        </div>
        <div className={classes.url}>
          https://www.instagram.com/sotharoth_yim/
        </div>
        <div className={classes.icon}>
          <FiExternalLink
              onClick={()=>{
                handleNavigate("instagram.com/sotharoth_yim/")
              }}
          />
        </div>
      </div>

      {/* github */}
      <div className={classes.row}>
        <div className={classes.icon}>
          <img src={icons.github} className={classes.iconLogo}/>
        </div>
        <div className={classes.url}>
          https://github.com/JBeanny
        </div>
        <div className={classes.icon}>
          <FiExternalLink
              onClick={()=>{
                handleNavigate("github.com/JBeanny")
              }}
          />
        </div>
      </div>

      {/* email */}
      <div className={classes.row}>
        <div className={classes.icon}>
          <img src={icons.gmail} className={classes.iconLogo}/>
        </div>
        <div className={classes.url}>
            yimsotharoth999@gmail.com
        </div>
        <div className={classes.icon}>
          <FiCopy
            onClick={()=>{
              handleCopy("yimsotharoth999@gmail.com")
            }}
          />
          <AiOutlineMail
            onClick={()=>{
              window.location.assign('/sendMessage')
            }}
          />
        </div>
      </div>
      <div>
        <ToastContainer
          className={classes.toast}
          autoClose={500}
          pauseOnHover={false}
          position="bottom-center"
          hideProgressBar={true}
        ></ToastContainer>
      </div>
    </div>
  )
}

export default Contact
import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from 'react-toastify';
import classes from './Form.module.css';
import icons from '../../Images/image';
import {FiSend} from 'react-icons/fi';

const Form = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();

  const handleChange = (e, setValue) => {
    setValue(e.target.value);
  };

  const clearForm = () => {
    setFirstname("");
    setLastname("");
    setEmail("");
    setPhone("");
    setMessage("");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if( !email || !firstname || !lastname || !phone || !message){
        toast.warn(`Please fill in the form`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
        });
    }else{
        emailjs
        .sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            e.target,
            process.env.REACT_APP_PUBLIC_KEY
        )
        .then(() => {
                toast.success(`Message Sent, We will get back to you shortly`, {
                    position: "top-center",
                    autoClose: 500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                });
                clearForm();
            },
            (error) => {
                toast.error(`Error occured, Please try again, ${error.text}`, {
                    position: "top-center",
                    autoClose: 500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        );
    }
  };

  return (
    <div className={classes.container}>
        <div className={classes.imageContainer}>
            <div className={classes.textContainer}>
                <div className={classes.textTitle}>
                    have some questions ?
                </div>
                <div className={classes.text}>
                    Send me an Email, And I will respond in an instance
                </div>
            </div>
            <img
                className={classes.image}
                src={icons.mail}
            />
        </div>
        <form ref={form} onSubmit={handleSubmit} className={classes.form}>    
            <div className={classes.formTextContainer}>
                <div className={classes.formText}>
                    You are about to send Email to Yim Sotharoth 
                </div>
                <img
                    className={classes.logo}
                    src={icons.logo}
                />
            </div>
            <input
                className={classes.input}
                type="text"
                placeholder="Firstname"
                value={firstname}
                name="firstname"
                onChange={(e) => {
                handleChange(e, setFirstname);
                }}
            />
            <input
                className={classes.input}
                type="text"
                placeholder="Lastname"
                value={lastname}
                name="lastname"
                onChange={(e) => {
                handleChange(e, setLastname);
                }}
            />
            <input
                type="email"
                className={classes.input}
                placeholder="Your Email"
                value={email}
                name="email"
                onChange={(e) => {
                handleChange(e, setEmail);
                }}
            />
            <input
                type="text"
                className={classes.input}
                placeholder="Phone number"
                value={phone}
                name="phone"
                onChange={(e) => {
                handleChange(e, setPhone);
                }}
            />
            <textarea
                type="text"
                className={classes.inputMessage}
                placeholder="Your message"
                value={message}
                name="message"
                onChange={(e) => {
                handleChange(e, setMessage);
                }}
            />
            <button type="submit" className={classes.button}><FiSend/> &nbsp; send email</button>
        </form>
        <ToastContainer
            className={classes.toastText}
            autoClose={500}
            pauseOnHover={false}
            position="bottom-center"
            hideProgressBar={true}
        ></ToastContainer>
    </div>
  );
};

export default Form;

import React,{useState} from 'react'
import classes from './navigation.module.css';
import { Link } from 'react-router-dom'
import {AiFillHome,AiFillPhone} from 'react-icons/ai'
import {RiMacbookFill} from 'react-icons/ri';

const Navigation = () => {

    const [linkUpdate,setLinkUpdate] = useState(false);

    const isActive = {
        Contact: window.location.href.includes("contact"),
        Class: window.location.href.includes("class"),
        Home: window.location.href.includes("home") || 
        !window.location.href.includes("contact") && 
        !window.location.href.includes("class"),
    }

    const onClickHandler = () => {
        setLinkUpdate(previous => !previous)
    }

    return (
        <div className={classes.container}>
            <div className={classes.linkContainer}>
                <div className={classes.linkActiveBg} 
                    style={{backgroundColor: isActive.Home ? "#DFDFDE" : "transparent"}}
                >
                    <Link to="/home">
                        <AiFillHome
                            onClick={onClickHandler}
                            className={
                                isActive.Home
                                ?
                                classes.activeLink :
                                classes.inactiveLink
                            }
                        />
                    </Link>
                </div>

                <div className={classes.linkActiveBg}
                    style={{backgroundColor: isActive.Contact ? "#DFDFDE" : "transparent"}}
                >
                    <Link to="/contact">
                        <AiFillPhone
                            onClick={onClickHandler}
                            className={
                                isActive.Contact ?
                                classes.activeLink :
                                classes.inactiveLink
                            }
                        />
                    </Link>
                </div>

                <div className={classes.linkActiveBg}
                    style={{backgroundColor: isActive.Class ? "#DFDFDE" : "transparent"}}
                >
                    <Link to="/class">
                        <RiMacbookFill
                            onClick={onClickHandler}
                            className={
                                isActive.Class ?
                                classes.activeLink :
                                classes.inactiveLink
                            }
                        />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Navigation
import React from 'react'
import classes from './carousel.module.css';
import item from './CarouselObj';
import {AiOutlineArrowRight} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const Projects = () => {
    const navigate = useNavigate();

    const handleSeeMoreClick = (item) => {
        navigate("/detail",{state:{item:item}});
    }

    return(
        <div className={classes.container}>
            {
                item.map((value,key)=> {
                    return (
                        <div className={classes.contentContainer} key={key}>
                            <div className={classes.detailContainer}>
                                <div className={classes.detailText}>
                                    {value.project_name}
                                </div>
                            </div>
                            <div className={classes.carouselContainer}>
                                <div className={classes.imageContainer}>
                                    <img
                                        className={classes.image}
                                        src={value.image}
                                    />
                                </div>
                            </div>
                            <div 
                                className={classes.seeMore}
                                onClick={()=>{
                                    handleSeeMoreClick(value);
                                }}
                            >
                                <div className={classes.seeMoreText}>
                                    See More <AiOutlineArrowRight/>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </div>
    )
}

export default Projects
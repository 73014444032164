import React from 'react'
import classes from './header.module.css'

import profileImage from "../../Images/backImage.jpg"

import {AiOutlineFacebook,AiOutlineGithub,AiOutlinePhone,AiOutlineMail,AiOutlineCopyrightCircle} from 'react-icons/ai';


const Header = () => {
  return (
    <div className={classes.profileHeader}>
    <div className={classes.triangleBottomleft}></div>

    <div className={classes.profile}>
        <div className={classes.imageProfileContainer}>
            <img
              className={classes.imageProfile}
              src={profileImage}
            />
        </div>
        <div className={classes.personalInfo}>

          <div className={classes.personalData}>
              <div data-text="Sotharoth" className={classes.Firstname}>
                  Sotharoth
              </div>
              <div data-text="Yim" className={classes.Lastname}>
                  Yim 
              </div>
              <div className={classes.quote}>
                  I make metaphors into realistic truths
              </div>
          </div>

          <div className={classes.position}>
              <h2 className={classes.positionText}>
                Junior Web Developer
              </h2>
              <div className={classes.contact}>
                  <div className={classes.link}>
                    <AiOutlineFacebook className={classes.icon}/>
                    <h3 className={classes.linkText}>Facebook</h3>
                  </div>
                  <div className={classes.link}>
                    <AiOutlineGithub className={classes.icon}/>
                    <h3 className={classes.linkText}>GitHub</h3>
                  </div>
                  <div className={classes.link}>
                    <AiOutlineMail className={classes.icon}/>
                    <h3 className={classes.linkText}>yimsotharoth999@gmail.com</h3>
                  </div>
                  <div className={classes.link}>
                    <AiOutlinePhone className={classes.icon}/>
                    <h3 className={classes.linkText}>(+855) 77 797 782</h3>
                  </div>
              </div>
          </div>

        </div>
    </div>
  </div>
  )
}

export default Header
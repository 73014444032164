import React from 'react';
import classes from './seeMore.module.css';
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const SeeMore = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const data = location.state.item;

    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <AiOutlineArrowLeft 
                    className={classes.goBack}
                    onClick={goBack}
                />
                <div className={classes.titleText}>
                    {data.project_name}
                </div>
            </div>
            <Carousel
                className={classes.slideShow}
                autoPlay={true}
                infiniteLoop={true}
                showArrows={false}
                // showIndicators={false}
                showThumbs={false}
                showStatus={false}
                interval={3000}
            >
                <div className={classes.imageContainer}>
                    <img
                        className={classes.image}
                        src={data.image}
                    />
                </div>
                <div className={classes.imageContainer}>
                    {
                        data.preview1 ? 
                        <img
                            className={classes.image}
                            src={data.preview1}
                        />
                        : 
                        <img
                            className={classes.image}
                            src={data.image}
                        />
                    }
                </div>
                <div className={classes.imageContainer}>
                    {
                        data.preview2 ? 
                        <img
                            className={classes.image}
                            src={data.preview2}
                        />
                        :
                        <img
                            className={classes.image}
                            src={data.image}
                        />
                    }
                </div>
            </Carousel>

            <div className={classes.contextContainer}>
                <div className={classes.language}>   
                    {data.languages}
                </div>
                <div className={classes.description}>
                    {data.description}
                </div>
            </div>
        </div>
    )
}

export default SeeMore
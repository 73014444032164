import React from "react";
import classes from "./container.module.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";

const Container = (props) => {
  return (
    <div className={classes.mainContainer}>
      <Header />
      <Navigation />
      {props.children}
      <Footer />
    </div>
  );
};

export default Container;

import React, { useState } from "react";
import classes from "./class.module.css";

import Office from "../office/Office";
import Course from "../Course/Course";
import Projects from "../Projects/Projects";

const Class = () => {
  const [officePageClick, setOfficePageClick] = useState(false);
  const [webPageClick, setWebPageClick] = useState(false);
  const [carouselClick,setCarouselClick] = useState(true);

  const handleSwappingPage = (link) => {
    if (link === "office") {
      setOfficePageClick(true);
      setWebPageClick(false);
      setCarouselClick(false);
    } else if(link === "web"){
      setOfficePageClick(false);
      setWebPageClick(true);
      setCarouselClick(false);
    }else{
      setOfficePageClick(false);
      setWebPageClick(false);
      setCarouselClick(true);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.navigation}>
        <div
          className={carouselClick ? classes.activeLink : classes.link}
          onClick={() => {
            handleSwappingPage("experience");
          }}
        >
          Projects
        </div>
        <div
          className={officePageClick ? classes.activeLink : classes.link}
          onClick={() => {
            handleSwappingPage("office");
          }}
        >
          Office Works & Webs
        </div>
        <div
          className={webPageClick ? classes.activeLink : classes.link}
          onClick={() => {
            handleSwappingPage("web");
          }}
        >
          Programming Courses
        </div>
      </div>
      <div className={classes.content}>
        {officePageClick ? <Office/> : ""}
        {webPageClick ? <Course/> : ""}
        {carouselClick ? <Projects/> : ""}
      </div>
    </div>
  );
};

export default Class;
